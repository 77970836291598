<template>
  <div>
    <v-card>
      <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
        <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>

      <v-toolbar   flat>
          <v-toolbar-title>Offer Letter Report</v-toolbar-title>
          <v-spacer />
      </v-toolbar>
         
      <v-row class='pl-4 pt-5'>
        <v-col md="3">
          <v-autocomplete :items="ay_list" item-text="ay" item-value="id" v-model="ay" outlined>
            <template #label>
    Academic Year<small style="color: red">*</small>
  </template>
          </v-autocomplete>
        </v-col>
      <v-col md="3">
          <v-autocomplete         
            :items="institute_list" item-text="name" item-value="id"
            v-model="institute" outlined
            @change="getprogramTypelist()"
          >
          <template #label>
    Institute<small style="color: red">*</small>
  </template>
        </v-autocomplete>
        </v-col>
        <v-col md="3">
         <v-select
              autocomplete="off"
              placeholder="Select Program Type"
              :items="programtype_list"
              @change="getprogramlist()"
              v-model="selectedprgtype"
              item-text="name"
              item-value="id" outlined
            >
            <template #label>
    Program Type<small style="color: red">*</small>
  </template>
          </v-select>
            </v-col>
        <v-col md="3">
          <v-autocomplete
            autocomplete="off" 
            :items="program_list"
            item-text="name" item-value="id"
            v-model="program" outlined
          >
          <template #label>
    Program<small style="color: red">*</small>
  </template>
        </v-autocomplete>
        </v-col>
      
   <v-col md="3">

<v-autocomplete outlined :items="year_list" item-text="name" item-value="id" v-model="year">
  <template #label>
    Year<small style="color: red">*</small>
  </template>
</v-autocomplete>
</v-col>
       
        <!-- <v-col md="2">
           <label>Company  &nbsp;<small style="color:red;">*</small></label>
          <v-autocomplete      
            :items="company_list"
            item-value="id"
            item-text="name"
            v-model="company"
          ></v-autocomplete>
        </v-col> -->
        
      </v-row>
      <v-row>
        <v-col ><center>
          <v-btn class="mt-3 logi" color="primary darken-1" @click="fetchReport()">Fetch Report</v-btn>
          </center>
        </v-col>
      </v-row>
    </v-card>
    <br />
     
    <div>
     <!-- <v-data-table :headers="headers" :items="stud_list" class="elevation-1" :search="search" v-if="stud_list.length!=0">
        <template v-slot:top>
          <v-toolbar flat color="white" align="center" justify="center">
            <v-toolbar-title>Report</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <download-excel
              class="mar"
              v-if="stud_list.length != 0"
              color="primary"
              dark
              :data="stud_list"
              :fields="table_fields"
              worksheet="My Worksheet"
              name="Placement Report.xls"
            >
              <!-- <v-btn class="ma-2 no-display" tile outlined color="success">
                <v-icon left>mdi-file-excel</v-icon>Excel
                <v-icon right>mdi-download</v-icon>
              </v-btn>-->
             <!-- <v-btn color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
            </download-excel>
          </v-toolbar>
        </template>
      </v-data-table>-->
      
       <v-row justify="end" class="mr-5">
           <v-btn v-if="learner_list.length != 0" style="margin-right:20px" color="blue" dark @click="downloadallOffer(learner_list)"
                        >Download Offerletters</v-btn
                      >
        <v-btn v-if="learner_list.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
          <v-icon dark>mdi-file-excel</v-icon>
        </v-btn>
      </v-row>
      
      <v-simple-table id="exceltable"  class="elevation-1" :search="search" v-if="learner_list.length!=0">
       
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 10%;" class="text-left" @click.prevent="sortBy('srno')"> Sr. No.
               <v-icon>mdi-swap-vertical</v-icon></th>
              <th  class="text-left" @click.prevent="sortBy('registration_number')"> PRN/GR. No. <v-icon>mdi-swap-vertical</v-icon></th>
              <th class="text-left" @click.prevent="sortBy('name')"> Name <v-icon>mdi-swap-vertical</v-icon></th>
              <th class="text-left" @click.prevent="sortBy('email')"> Email <v-icon>mdi-swap-vertical</v-icon></th>
              <th class="text-left" @click.prevent="sortBy('company')"> Company <v-icon>mdi-swap-vertical</v-icon></th>
              <th class="text-left" @click.prevent="sortBy('program')"> Program <v-icon>mdi-swap-vertical</v-icon></th>
              <th class="text-left" @click.prevent="sortBy('year')"> Year <v-icon>mdi-swap-vertical</v-icon></th>
              <th class="text-left" > Offer Letter</th>
              <!-- <v-col cols="12" sm="12">
                                        <label>CV File :</label>
                                        <v-file-input type="file" placeholder="Select CV file" prepend-icon="mdi-file" ref="file" show-size v-model="savedata.file" required="true"
                                        clearable
                                        ></v-file-input>
                                    </v-col> -->
            </tr>
            
          </thead>
          <tbody>
          
            <tr
              v-for="item in learner_list"
              :key="item.id"
            >
              <td>{{ item.srno }}</td>
              <td>{{ item.registration_number }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.company }}</td>
              <td>{{ item.program }}</td>
              <td>{{ item.year }}</td>
              
              <td>
                
                <span v-if="item.offerletter != null" style="font-size:25px;color:DodgerBlue;">
                  <i class="mdi mdi-arrow-down-bold-circle-outline" @click="download(item)"
                  aria-hidden="true"></i>            
                </span>
                <span v-else>
                  -
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {table2excel} from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';
export default {
  data: () => ({
    dialogadd:false,
    reg:"",
    com:"",
    // dialog_icon:false,
     savedata: {
                file: null,
                id:"",
            },
    id:"",
    snackbar_msg: "",
    color: "",
    snackbar: false,
    placement_type_list: [],
    learner_list: [],
    sortKey: '',
    sortDesc: false,
   year_list: [],
   ay_list:[],
    sem_list: [],
    company_list: [],
    institute_list: [],
    program_list: [],
    programtype_list:[],
    search: "",
    ay: "",
    year: "",
    selectedprgtype:null,
    sem: null,
    company: null,
    institute: null,
    program: null,
    offer_type: [],
    offter_index:[],
    headers: [
      { text: "Sr. No.", value: "id", sortable: true },
      { text: "GR. No.", value: "registration_number", sortable: true },
      { text: "Name", value: "name", sortable: true },
      { text: "Company", value: "company", sortable: true },
      { text: "Department", value: "department", sortable: true },
      { text: "Branch", value: "branch", sortable: true },
      { text: "Academic Year", value: "ay", sortable: true },
      { text: "Year", value: "year", sortable: true },
      { text: "Offerletter", value: "offerletter", sortable: true },
     
    
    ],
    table_fields: {
      "Sr. No.": "id",
      "GR. No.": "registration_number",
      Name: "name",
      "Company Name": "company",
      "Offer Type": "offer_type",
      Department: "department",
      Branch: "branch",
      "Academic Year": "ay",
      Year: "year",
      Organization: "organization"
    },
  excelfile: null,
    exceldialog: false, 
    url: "",
    excelerrordialog: false,
    notfoundstudent: [],
    

  }),
  mounted() {
    axios
      .post("/TPO/offerletterreport")
      .then(res => {
    
        if (res.data.msg == "200") {
           
          this.year_list = res.data.year_list.sort();
          this.ay_list = res.data.ay_list.sort();
          this.company_list = res.data.company_list;
          this.institute_list = res.data.institute_list;
          this.program_list = res.data.allprogram_list;
           this.company_list.push({
                    name: 'All',
                    text: ''
          })
          this.company="All"

          this.program_list.push({
                    name: 'All',
                    text: ''
          })
          this.program="All"

          this.programtype_list.push({
                    name: 'All',
                    text: ''
          })
          this.selectedprgtype="All"
          this.year_list.push({
                    name: 'All',
                    text: ''
          })
          this.year="All"
           this.institute_list.push({
                    name: 'All',
                    text: ''
          })
          this.institute="All"
          this.ay_list.push({
            ay: 'All',
            text:''
          })
          this.ay="All"
          
    
          //console.log(" fetching data!");
        } else {
          //console.log("error fetching data!");
        }
      });
      // .catch(error => {
      //   this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
      //   window.console.log(error);
      // });
  },
  methods: {
    sortBy(key) {
    this.sortKey = key;
    this.sortDesc = !this.sortDesc; // Toggle sorting direction
    this.learner_list.sort((a, b) => {
      const order = this.sortDesc ? -1 : 1;
      if (a[key] < b[key]) return -1 * order;
      if (a[key] > b[key]) return 1 * order;
      return 0;
    });
  },
    download(item){
      //console.log(item);
       var data = {
        id: item.id,
        learnerid:item.learner_id,

      };

      axios.post("/TPO/downloadofferletterinReport", data).then((res) => {
        if (res.data.msg == "200") {
          window.open(res.data.offerurl, "_blank");
        }
      });
    },
     exportexcel() {    
        $("#exceltable").table2excel({  
          name: "Worksheet Name",
          filename: "Placement_REPORT", //do not include extension
          fileext: ".xls" // file extension
        });        
      },
     


   dialogicon(item){
          // alert("helooooo");
          //  alert(item.registration_number);
           this.reg=item.registration_number;
           this.com=item.company;
      this.dialogadd=true;
   },
    fetchReport() { 
        const data = {
        instituteid: this.institute,
        year_id:this.year,
        program_id:this.program,
        ay: this.ay,
      };
        axios
        .post("TPO/getOfferletterreport",data)
        .then((res) => {
          if (res.data.msg == "200") {
           this.learner_list = res.data.learner_list;
           //console.log("learner ");
           //console.log(this.learner_list)
            if(this.learner_list.length < 1){
              this.showSnackbar("red", "Data Not Found");
            }
          } else {
           this.showSnackbar("green", res.data.msg1);
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
    },

    
      downloadallOffer(corl_list) {     
     //alert("on download ")
      var params = {
        stud_list: corl_list,
      };
      axios.post("/TPO/dwOfferlettersfromofferletterreport", params).then((res) => {
        if (res.data.code == "200") {
            //console.log("after 200 ")
            //console.log(res.data.offerlist);
             const zip = new JSZip();
          let count = 0;
          const zipFilename = "offer.zip";
          var offer= res.data.offerlist;
          //console.log("offfer")
          //console.log(offer)
          offer.forEach(async function (url){
            //console.log("for each  ")
            // const urlArr = url.split('?')[0].split("/")
            // const filename = urlArr[urlArr.length - 1];
            // console.log(filename)
        
            JSZipUtils.getBinaryContent(url.url, function (err, data) {
              //console.log("in  jszutil ")
                  if(err) {
                    console.log(err);
                      throw err; 
                      // or handle the error
                  }
                  zip.file(url.grno, data, {binary:true});
                  count++;
                  if (count === res.data.offerlist.length) {
                      zip.generateAsync({type:'blob'}).then(function(content) {
                          saveAs(content, zipFilename);
                      });
                  }
              });

          });
        }
      });
    },
    getPrograms(){
     // alert("in get programs"+this.institute);     
           const data = {
        instituteid: this.institute,
      };
        axios
        .post("TPO/getPrograms",data)
        .then((res) => {
          if (res.data.msg == "200") {
           this.program_list = res.data.program_list;
         
            this.program_list.push({
                    name: 'All',
                    text: ''
          })
          this.program="All"
          } else {
           this.showSnackbar("green", res.data.msg1);
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
      
    },
    getprogramTypelist(){
       this.programtype_list=[]
      var params = {
        instituteid: this.institute,
      };

      axios.post("/TPO/getprogramTypelist", params).then((res) => {
        //console.log(res.data);
        if (res.data.msg == "200") {
          this.programtype_list = res.data.programtype_list;
          //console.log("this.programtype_list");
          // console.log(this.programtype_list);
        } else {}
      });
    },
    getprogramlist(){
      //  this.programtype_list=[]
      var params = {
        instituteid: this.institute,
        selectedprgtype:this.selectedprgtype,
      };

      axios.post("/TPO/getprogramlist", params).then((res) => {
        //console.log(res.data);
        if (res.data.msg == "200") {
          this.program_list = res.data.program_list;
           this.program_list.push({
                    name: 'All',
                    text: ''
          })
          this.program="All"
          this.year_list=res.data.year_list;
            this.year_list = res.data.year_list;
          this.year_list.push({
                    name: 'All',
                    text: ''
          })
          this.year="All"
          //console.log("this.programtype_list");
          //console.log(this.programtype_list);
        } else {}
      });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
        clear() {
      this.excelfile = null;
      this.exceldialog = false;
    },
       closeerrordialog() {
      this.clear();
      this.excelerrordialog = false;
    },
  }
};
</script>
<style scoped>
.required{
  color:red;
  font-size:14px;
  font-style:bold;
}

.tc-title {
        background-image: linear-gradient(-90deg, skyblue, #057996);
        color: #fff;
        border-radius: 3px;
}
</style>    